import { Injectable, computed, signal } from '@angular/core';
import { AllRolesResponse } from '@pi/pi-common/src/ui-dtos/role.dtos';

import { Role } from '../../user-management/user-management.interface';
import { LOGOUT_TEARDOWN_TOKEN } from '../tokens/logout-teardown-token';

@Injectable({ providedIn: 'root' })
export class RoleCache {
    public readonly roles = signal<Role[]>([]);
    public readonly assignableRoles = signal<AllRolesResponse>([]);
    public readonly roleMap = computed(() =>
        this.roles().reduce<Record<number, Role>>((acc, role) => {
            acc[role.id] = role;
            return acc;
        }, {})
    );

    public setRoles(assignableRoles: AllRolesResponse, roles: Role[]): void {
        this.assignableRoles.set(assignableRoles);
        this.roles.set(roles);
    }

    public clearRoles(): void {
        this.roles.set([]);
        this.assignableRoles.set([]);
    }
}

export const ROLE_CACHE_TEARDOWN = {
    provide: LOGOUT_TEARDOWN_TOKEN,
    multi: true,
    deps: [RoleCache],
    useFactory: (roleCache: RoleCache) => () => roleCache.clearRoles()
};
